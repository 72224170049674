<template>
	<div class="mx-5 mt-5">
        <v-row>
            <v-col>
                <h4>Type of DET Application
                    <v-icon @click="viewLink"> mdi-information</v-icon>
                </h4>
                
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="10"
                sm="10"
                md="10"
                >
                <v-autocomplete
                    v-if="currUser.is_det_encoder == '0'"
                    dense
                    solo
                    :items="items"
                    placeholder="DET Type"
                    v-model="detDetails.det_type"
                    :readonly="disabled"
                >
                </v-autocomplete>
                <v-autocomplete
                    v-else
                    dense
                    solo
                    :items="items"
                    placeholder="DET Type"
                    v-model="detDetails.det_type"
                    readonly
                >
                </v-autocomplete>
            </v-col>
        </v-row>

        <v-row>
			<v-col cols="12">
                <DetUploading/>
			</v-col>
		</v-row>

        <v-row>
            <v-col>
                <h4>Sub-agent Information</h4>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="2">
                <h5>Name:</h5>
            </v-col>
            <v-col cols="4">
                {{ primaryContactInfo.first_name + ' ' + primaryContactInfo.middle_name + ' ' + primaryContactInfo.last_name }}
            </v-col>
            <v-col cols="2">
                <h5>Email:</h5>
            </v-col>
            <v-col cols="4">
                {{ primaryContactInfo.email_address }}
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="2">
                <h5>Address:</h5>
            </v-col>
            <v-col cols="4">
                {{ primaryContactInfo.address_line_1 }}
            </v-col>
            <v-col cols="2">
                <h5>Mobile No.:</h5>
            </v-col>
            <v-col cols="4">
                {{ primaryContactInfo.cellphone }}
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-text-field
                    label="Citizenship"
                    outlined
                    dense
                    hide-details="auto"
                    :readonly="disabled"
                    v-model="detDetails.citizenship"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
            <v-col cols="6">
                <v-text-field
                    label="Tin No."
                    outlined
                    dense
                    hide-details="auto"
                    v-model="detDetails.tin_no"
                    :readonly="disabled"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-text-field
                    label="Fax. No"
                    outlined
                    dense
                    hide-details="auto"
                    v-model="detDetails.fax_no"
                    :readonly="disabled"
                    :rules="genericRule"
                ></v-text-field>
            </v-col> 
        </v-row>
	</div>
</template>

<script>

import { mapGetters } from 'vuex'
import DetUploading from './DetUploading.vue';
export default {
    
    props: ['disabled'],
    data () {
      return {
        dialog: false,
        items: ['Registration of a MSB - Stage 1', 'Registration of a MSB - Stage 2', 'Change of Registered/Business Name (DET to generate Form No. 2M)', 'Change in Ownership or Control', 'Reclassification of Registration Category', 'Establishment of New Branches and/or Newly-accredited Remittance Sub-agents (DET to generate Form No. 4M)', 'Additional/Terminated Remittance Tie-up Partner/s (DET to generate Form No. 5M)','Transfer of Location of Office/Remittance Sub-agent (DET to generate Form No. 6M)','Additional/Terminated Business Activity (DET to generate Form No. 7M)','Change of Branch/Remittance Sub-agent Name (DET to generate Form No. 8M)','Closure of Branch/Remittance Sub-agent (DET to generate Form No. 9M)','Closure of Business (DET to generate Form No. 10M)'],
      }
    },
    computed: {
		...mapGetters({
			primaryContactInfo: 'salesProspecting/primaryContactInfo',
            detDetails: 'bsp/detDetails',
            currUser: 'auth/currUser',
            existingDetFiles: 'bsp/existingDetFiles',
            genericRule: 'salesProspecting/genericRule',
		}),
	},
    components: {
		DetUploading: DetUploading
	},
    methods: {
        viewLink(){
            window.open('https://www.bsp.gov.ph/Pages/Regulations/GuidelinesOnTheEstablishmentOfBanks/RegistrationOfPawnshopsAndMoneyServiceBusiness.aspx', '_blank');
        }
    }
}
</script>
