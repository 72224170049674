<template>
    <v-expansion-panels class="px-5">
        <v-expansion-panel v-for="(parentItem, parentIndex) in existingUploadedBranches" :key="parentIndex" class="mb-3">
            <v-expansion-panel-header>
                    {{parentItem.branch_name}} (FLA)
            </v-expansion-panel-header>
            <!-- <v-expansion-panel-header>
                    Branch {{parentIndex+1}} FLA
            </v-expansion-panel-header> -->
         <v-expansion-panel-content eager>

            <v-row>
                <v-expansion-panels>
                    <v-expansion-panel v-for="(item, index) in parentItem.fla" :key="index" class="mt-3">
                        
                        <v-expansion-panel-header>
                                <b>{{item.first_name +' ' + item.middle_name + ' ' + item.last_name}}</b>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content eager>

                                <v-row>
                                    <v-col cols="2">
                                        <h5>Name:</h5>
                                    </v-col>
                                    <v-col cols="4">
                                        {{item.first_name +' ' + item.middle_name + ' ' + item.last_name}}
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Email"
                                            outlined
                                            dense
                                            hide-details="auto"
                                            v-model="item.email_address"
                                            :readonly="disabled"
                                        ></v-text-field>
                                    </v-col> 
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Citizenship"
                                            outlined
                                            dense
                                            hide-details="auto"
                                            v-model="item.citizenship"
                                            readonly
                                        ></v-text-field>
                                    </v-col> 
                                </v-row>

                                <v-row>
                                    <v-col :cols="6">
                                        <v-menu
                                            v-model="item.menuExpiry"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="item.date_of_seminar"
                                                    label="Date of AMLA Seminar Attended"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="item.date_of_seminar"
                                                @input="item.menuExpiry = false"
                                                :readonly="disabled"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Tin No."
                                            outlined
                                            dense
                                            v-model="item.tin_no"
                                            hide-details="auto"
                                            :readonly="disabled"
                                        ></v-text-field>
                                    </v-col> 
                                </v-row>

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Tel No."
                                            outlined
                                            dense
                                            hide-details="auto"
                                            v-model="item.tel_no"
                                            :readonly="disabled"
                                        ></v-text-field>
                                    </v-col> 
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Fax No."
                                            outlined
                                            dense
                                            hide-details="auto"
                                            v-model="item.fax_no"
                                            :readonly="disabled"
                                        ></v-text-field>
                                    </v-col> 
                                </v-row>

                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Address"
                                            outlined
                                            dense
                                            hide-details="auto"
                                            v-model="item.address"
                                            :readonly="disabled"
                                        ></v-text-field>
                                    </v-col> 
                                </v-row>

                            <v-row class="mt-2 mx-3 mb-2">
                                <v-col v-if="item.email_address && item.citizenship && item.date_of_seminar && item.tin_no && item.tel_no && item.fax_no && item.address">
                                    <v-btn outlined color="primary" style="float:right;" @click="updateFla(item)" :disabled="disabled">
                                        Update FLA
                                    </v-btn>
                                </v-col>
                                <v-col v-else>
                                    <v-btn outlined color="primary" style="float:right;" disabled>
                                        Update FLA
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>

         </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    
</template>
<script>

import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
    props: ['disabled'],
    data () {
		return {

		};
	},
    computed: {
        ...mapGetters({
            existingUploadedBranches: 'salesProspecting/existingUploadedBranches',
        })
    },
    async created () {
        const resSP = await this.$store.dispatch('bsp/doGetSalesProspecting', this.$route.params.id)
        console.log(resSP.data.result[0].sp_branch)
        this.decodeBranches(resSP.data.result[0].sp_branch.data)
    },
    methods: {
        decodeBranches (resBranches) {
			let expiryFile = {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            }
            let nonExpiryFile = {
                file: null
            }
			resBranches.forEach(function (e) {
				e.branch_id = e.id
				e.businessPermitUploaded = JSON.parse(e.business_permit)
				e.justificationUploaded = JSON.parse(e.justification)
				e.operating_hours = JSON.parse(e.operating_hours)
				e.operating_hours.forEach(function (g) {
					g.isClosed = parseInt(g.isClosed)
				})
				e.business_permit = _.clone(expiryFile)
				e.justification = _.clone(nonExpiryFile)
				e.fla.forEach(function (f) {
					f.amlaUploaded  = JSON.parse(f.amla)
					f.drugTestUploaded = JSON.parse(f.drug_test)
					f.nbiUploaded = JSON.parse(f.nbi)
					f.amla = _.clone(expiryFile)
					f.drug_test = _.clone(expiryFile)
					f.nbi = _.clone(nonExpiryFile)
                    f.menuExpiry = false
				})
			})
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_BRANCHES', resBranches)
		},
        // decodeBranches (resBranches) {

        //     resBranches.map(data => {
        //         if (!data.is_draft){
        //             this.filteredBranch.push(data)
        //         }
        //     })
        //     console.log(this.filteredBranch)

		// 	let expiryFile = {
        //         file: null,
        //         date_issued: '',
        //         date_expiry: '',
        //         menuIssued: false,
        //         menuExpiry: false
        //     }
        //     let nonExpiryFile = {
        //         file: null
        //     }
		// 	this.filteredBranch.forEach(function (e) {
		// 		e.branch_id = e.id
		// 		e.businessPermitUploaded = JSON.parse(e.business_permit)
		// 		e.justificationUploaded = JSON.parse(e.justification)
		// 		e.operating_hours = JSON.parse(e.operating_hours)
		// 		e.operating_hours.forEach(function (g) {
		// 			g.isClosed = parseInt(g.isClosed)
		// 		})
		// 		e.business_permit = _.clone(expiryFile)
		// 		e.justification = _.clone(nonExpiryFile)
		// 		e.fla.forEach(function (f) {
		// 			f.amlaUploaded  = JSON.parse(f.amla)
		// 			f.drugTestUploaded = JSON.parse(f.drug_test)
		// 			f.nbiUploaded = JSON.parse(f.nbi)
		// 			f.amla = _.clone(expiryFile)
		// 			f.drug_test = _.clone(expiryFile)
		// 			f.nbi = _.clone(nonExpiryFile)
        //             f.menuExpiry = false
		// 		})
		// 	})
		// 	this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_BRANCHES', this.filteredBranch)
		// },
        async updateFla(item){
            const payload = {
                id : this.$route.params.id,
                finayPayload : {
                    fla_id: item.id,
                    email_address: item.email_address,
                    citizenship: item.citizenship,
                    date_of_seminar: item.date_of_seminar,
                    tin_no: item.tin_no,
                    tel_no: item.tel_no,
                    fax_no: item.fax_no,
                    address: item.address
                }
            }
            const res = await this.$store.dispatch('bsp/doUpdateFla',{payload})
            if (res.status === 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'FLA Updated Successfully!','messagetype':'success'});
            }
        },
    }
}
</script>
