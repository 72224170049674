<template>
    <div>
        <v-row>
            <v-col :cols="9">
                <h5>Data Entry Template (DET) to generate FSS Licensing Form No. 03-001 (Application for Registration to Operate a MSB)
                    <v-icon @click="downloadFile1"> mdi-cloud-download</v-icon>
                </h5>
                
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    v-model="detFilesUpload.det_fss_03_001.file"
                    :disabled="disable_encoder_view"
                    :rules="detEncoderRule(existingDetFiles.det_fss_03_001)"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingDetFiles.det_fss_03_001 != null">
                <v-icon
                    @click="retrieveUploaded(existingDetFiles.det_fss_03_001)"
                    >
                    mdi-download-box-outline
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <h5>List of Remittance Tie-up Partners (if applicable) (DET to generate FSS Licensing Form No. 03-009 )
                    <v-icon @click="downloadFile4"> mdi-cloud-download</v-icon>
                </h5>
                
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    v-model="detFilesUpload.det_form_03_009.file"
                    :disabled="disable_det_view"
                    :rules="checkRule(existingDetFiles.det_form_03_009)"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingDetFiles.det_form_03_009 != null">
                <v-icon
                    @click="retrieveUploaded(existingDetFiles.det_form_03_009)"
                    >
                    mdi-download-box-outline
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <h5>FSS Licensing Form No. 03-003 Deed of Undertaking (for applicant/entity)
                    <v-icon @click="downloadFile2"> mdi-cloud-download</v-icon>
                </h5>
                
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    v-model="detFilesUpload.fss_03_003.file"
                    :disabled="disable_det_view"
                    :rules="checkRule(existingDetFiles.fss_03_003)"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingDetFiles.fss_03_003 != null">
                <v-icon
                    @click="retrieveUploaded(existingDetFiles.fss_03_003)"
                    >
                    mdi-download-box-outline
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <h5>FSS Licensing Form No. 03-005 Personal Data and Authorization Form
                    <v-icon @click="downloadFile3"> mdi-cloud-download</v-icon>
                </h5>
                
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    v-model="detFilesUpload.fss_03_005.file"
                    :disabled="disable_det_view"
                    :rules="checkRule(existingDetFiles.fss_03_005)"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingDetFiles.fss_03_005 != null">
                <v-icon
                    @click="retrieveUploaded(existingDetFiles.fss_03_005)"
                    >
                    mdi-download-box-outline
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <h5>Letter of Intent with Business Plan</h5>
                
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    v-model="detFilesUpload.letter_of_intent.file"
                    :disabled="disable_det_view"
                    :rules="checkRule(existingDetFiles.letter_of_intent)"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingDetFiles.letter_of_intent != null">
                <v-icon
                    @click="retrieveUploaded(existingDetFiles.letter_of_intent)"
                    >
                    mdi-download-box-outline
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <h5>Proof of Payment</h5>
                
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    v-model="detFilesUpload.proof_of_payment.file"
                    :disabled="disable_det_view"
                    :rules="checkRule(existingDetFiles.proof_of_payment)"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingDetFiles.proof_of_payment != null">
                <v-icon
                    @click="retrieveUploaded(existingDetFiles.proof_of_payment)"
                    >
                    mdi-download-box-outline
                </v-icon>
            </v-col>
        </v-row>

    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {

    computed: {
		...mapGetters({
            currUser: 'auth/currUser',
            detFilesUpload: 'bsp/detFilesUpload',
            existingDetFiles: 'bsp/existingDetFiles',
            disable_encoder_view: 'bsp/disable_encoder_view',
            disable_det_view: 'bsp/disable_det_view'
		}),
	},
    methods: {
        detEncoderRule (item) {
            if (this.currUser.is_det_encoder == '1'){
				if (item == null) {
                    return [v => !!v || 'This field is required']
                }
			}
        },
        checkRule (item) {
            if (item == null) {
                return [v => !!v || 'This field is required']
            }
        },
        downloadFile1(){
            window.open('https://www.bsp.gov.ph/Regulations/Guidelines%20and%20other%20Regulations/DET_v2.xlsm', '_blank');
        },
        downloadFile2(){
            window.open('https://www.bsp.gov.ph/Regulations/Guidelines%20and%20other%20Regulations/FSS%20Licensing%20Form%20No.%2003-003.docm', '_blank');
        },
        downloadFile3(){
            window.open('https://www.bsp.gov.ph/Regulations/Guidelines%20and%20other%20Regulations/FSS%20Licensing%20Form%20No.%2003-005.docm', '_blank');
        },
        downloadFile4(){
            window.open('https://www.bsp.gov.ph/Regulations/Guidelines%20and%20other%20Regulations/DET_v2.xlsm', '_blank');
        },
        async retrieveUploaded (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
			const payload = {
				file: max.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			} else if (extension === 'xlsm') {
                blob = new Blob([file], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' })
            } else if (extension === 'xlsx') {
                blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            }
			var link = document.createElement('a');
            var fileURL = window.URL.createObjectURL(blob);
            console.log(res)
            link.href = fileURL;
            link.download = max.title;
            link.click();
            link.remove();
            window.URL.revokeObjectURL(fileURL);
		},
    },
}
</script>
