<template>
    <div>

		<v-snackbar
			color="red"
			v-model="snackbar"
			vertical
			top
			width="400"
			timeout=5000
			>
			<br>
			<div class="text-subtitle-1 pb-2">	
				<h4>Uploading of Documents Missing Files:</h4>
			</div>
			<br>
			<p v-for="(item, index) in toUpload" :key="index"> - {{item}}</p>
			<br>

			<div style="cursor: pointer;" @click="$router.push({ path: '/sales-prospecting/update/' + $route.params.id})">
				<p class="link">Upload Documents Here!</p>
			</div>

			<template v-slot:actions>
				<v-btn
				color="indigo"
				variant="text"
				@click="snackbar = false"
				>
				Close
				</v-btn>
			</template>
		</v-snackbar>

		<v-form ref="toValidate" v-model="toValidate">
			<v-row>
				<v-col cols="12">
					<profile-card  />
				</v-col>
			</v-row>
			
			<v-row>
				<v-col cols="12">
					<DetInformation/>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" class="mx-5">
					<h4>Branch/FLA Informations</h4>
				</v-col>
			</v-row>
			
			<v-row>
				<v-col cols="12">
					<BranchFla/>
					<div class="text-center mb-5" v-if="existingUploadedBranches.length >= 1">
						<v-pagination
						@input="changePage"
						v-model="page"
						:total-visible="5"
						:length="branchPage.length"
						></v-pagination>
					</div>
				</v-col>
				
			</v-row>
		</v-form>

			<v-row>
				<v-col cols="12" class="mx-5">
					<h4>Compliance Uploading</h4>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<ComplianceUploading :disabled="true"/>
				</v-col>
			</v-row>



		<v-row v-if="currUser.user_role === 'encoder' && currUser.is_det_encoder === '1'">
			<v-col cols="12" class="mt-5">
				<v-text-field
					height="70px"
					label="Comment"
					hide-details="auto"
					outlined
					dense
					@change="change"
				></v-text-field>
				<br>
			</v-col>
		</v-row>

		<!-- ENCODER BUTTONS -->

		<v-row v-if="currUser.user_role === 'encoder' && currUser.is_det_encoder === '0'">
			<v-col class="mx-5">
				<v-btn
					v-if="existingDetFiles.det_status == 'DET Compliance (Returned to Account Manager)'"
					color="primary"
					style="float:right;"
					@click="sendForComplianceApproval"
					>
					<b>Send to Compliance</b>
				</v-btn>
				<v-btn
					class="mr-4"
					color="primary"
					style="float:right;"
					@click="sendToDetEncoder"
					>
					<b>Send to DET ENCODER</b>
				</v-btn>
				<v-btn
					class="mr-4"
					outlined
					color="primary"
					style="float:right;"
					@click="saveDraft"
					>
					<b>Save as Draft</b>
				</v-btn>
				<v-btn
					class="mr-4"
					outlined
					color="primary"
					style="float:left;"
					@click="$router.go(-1)"
					>
					<b>Back</b>
				</v-btn>
			</v-col>
		</v-row>

		<!-- DET ENCODER BUTTONS -->

		<v-row v-else-if="currUser.user_role === 'encoder' && currUser.is_det_encoder === '1'">
			<v-col class="mx-5">
				<v-btn
					color="primary"
					style="float:right;"
					@click="sendForComplianceApproval"
					>
					<b>Send to Compliance</b>
				</v-btn>

				<v-btn
					class="mr-4"
					color="red"
					outlined
					style="float:right;"
					@click="returnToEncoder"
					>
					<b>Return to Account Manager</b>
				</v-btn>

				<v-btn
					class="mr-4"
					outlined
					color="primary"
					style="float:right;"
					@click="saveDraft"
					>
					<b>Save as Draft</b>
				</v-btn>
				<v-btn
					class="mr-4"
					outlined
					color="primary"
					style="float:left;"
					@click="$router.go(-1)"
					>
					<b>Back</b>
				</v-btn>
			</v-col>
		</v-row>

    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import jsonToFormData from 'json-form-data';
import ProfileCard from '../../components/modules/salesProspecting/ProfileCard.vue';
import DetInformation from '../../components/modules/BspModules/DetInformation.vue';
import BranchFla from '../../components/modules/BspModules/BranchFla.vue';
import ComplianceUploading from '../../components/modules/BspModules/ComplianceUploading.vue';
import _ from 'lodash';


export default{
	data () {
		return {
			sender: '',
			comment: '',
			toValidate: true,
			uploadingOfDocuments: '',
			snackbar: false,
			toUpload: [],
			page: 1,
			branchPage: []
		}
	},
	computed: {
		...mapGetters({
			primaryContactInfo: 'salesProspecting/primaryContactInfo',
			bspId: 'bsp/bspId',
			detDetails: 'bsp/detDetails',
			currUser: 'auth/currUser',
			existingDetFiles: 'bsp/existingDetFiles',
			detFilesUpload: 'bsp/detFilesUpload',
			existingUploadedBranches: 'salesProspecting/existingUploadedBranches'
		}),
	},
    components: {
		ProfileCard: ProfileCard,
		DetInformation: DetInformation,
		BranchFla: BranchFla,
		ComplianceUploading: ComplianceUploading
	},
	async created () {
		
		const resSP = await this.$store.dispatch('bsp/doGetSalesProspecting', this.$route.params.id)
		this.assignData(resSP.data.result[0])
		this.uploadingOfDocuments = resSP.data.result[0].sp_location[0]

		this.countBranchPage(resSP.data.result[0].sp_branch)

		const payload = {
            id : this.$route.params.id,
            finalPayload: {
              bsp_id: this.bspId
            }
          }

		const res = await this.$store.dispatch('bsp/doGetDetDetails', {payload})
			if (res.status === 200){
				console.log(res.data.result)
				this.$store.commit('bsp/SET_DET_DETAILS', res.data.result)
				this.reloadFiles()
			}

	},
	methods: {
		countBranchPage(e){
			this.page = 1
			this.branchPage = []
			if(e){
				let number = e.last_page
				for (let i = 1; i <= number; i++) {
					this.branchPage.push(i)
				}
			}
		},
		async changePage(e){
			const payload = {
                id : this.$route.params.id,
                page: e
            }
			const res = await this.$store.dispatch('bsp/changePageBranch', {payload})
			if(res.status === 200) {
				this.decodeBranches(res.data.result[0].sp_branch.data)
			}
		},
		decodeBranches (resBranches) {
			console.log(resBranches)
			let expiryFile = {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            }
			resBranches.forEach(function (e) {
				e.branch_id = e.id
				e.businessPermitUploaded = JSON.parse(e.business_permit)
				e.operating_hours = JSON.parse(e.operating_hours)
				e.operating_hours.forEach(function (g) {
					g.isClosed = parseInt(g.isClosed)
				})
				e.coordinates = JSON.parse(e.coordinates)
				e.business_permit = _.clone(expiryFile)
				e.fla.forEach(function (f) {
					f.amlaUploaded  = JSON.parse(f.amla)
					f.drugTestUploaded = JSON.parse(f.drug_test)
					f.nbiUploaded = JSON.parse(f.nbi)
					f.amla = _.clone(expiryFile)
					f.drug_test = _.clone(expiryFile)
					f.nbi = _.clone(expiryFile)
				})
			})
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_BRANCHES', resBranches)
			this.existingUploadedBranches.map(coords => {
                coords.coordinates.lat = parseFloat(coords.coordinates.lat)
                coords.coordinates.lng = parseFloat(coords.coordinates.lng)    
            })
		},
		async reloadFiles () {
            const payload = {
                id: this.$route.params.id,
                finalPayload: {
                    bsp_id: this.bspId
                }
            }
			const res = await this.$store.dispatch('bsp/doGetByBspId', {payload})
            if (res.status === 200){
                this.decodeDET(res.data.result)
				this.decodeDocs(res.data.result)
            }
		},
		decodeDocs(resDet){

            if (resDet.norr_fsd9 != null){
                resDet.norr_fsd9 = JSON.parse(resDet.norr_fsd9)
            }
            if (resDet.norr_fss_mail != null){
                resDet.norr_fss_mail = JSON.parse(resDet.norr_fss_mail)
            }
            if (resDet.oprn_invoice != null){
                resDet.oprn_invoice = JSON.parse(resDet.oprn_invoice)
            }
            if (resDet.oprn_receipt != null){
                resDet.oprn_receipt = JSON.parse(resDet.oprn_receipt)
            }
            if (resDet.settle_oprn_payment != null){
                resDet.settle_oprn_payment = JSON.parse(resDet.settle_oprn_payment)
            }
            if (resDet.accreditation_letter != null){
                resDet.accreditation_letter = JSON.parse(resDet.accreditation_letter)
            }

            this.$store.commit('bsp/SET_ISPROCESS_BSP', resDet.is_process_bsp)
            console.log(resDet.is_process_bsp)
            this.$store.commit('bsp/SET_COMPLIANCE_DOCS', resDet)
        },
        decodeDET (resDet) {

			console.log('here')

			// SET USER VIEW ACCESS

            if (resDet.fss_02_001 != null){
                resDet.fss_02_001 = JSON.parse(resDet.fss_02_001)
            }
            if (resDet.det_fss_03_001 != null){
                resDet.det_fss_03_001 = JSON.parse(resDet.det_fss_03_001)
            }
            if (resDet.fss_03_003 != null){
                resDet.fss_03_003 = JSON.parse(resDet.fss_03_003)
            }
            if (resDet.fss_03_005 != null){
                resDet.fss_03_005 = JSON.parse(resDet.fss_03_005)
            }
            if (resDet.det_form_03_009 != null){
                resDet.det_form_03_009 = JSON.parse(resDet.det_form_03_009)
            }
            if (resDet.det_form_2m != null){
                resDet.det_form_2m = JSON.parse(resDet.det_form_2m)
            }
            if (resDet.det_form_3m != null){
                resDet.det_form_3m = JSON.parse(resDet.det_form_3m)
            }
            if (resDet.form_3m_1 != null){
                resDet.form_3m_1 = JSON.parse(resDet.form_3m_1)
            }
            if (resDet.form_3m_2 != null){
                resDet.form_3m_2 = JSON.parse(resDet.form_3m_2)
            }
            if (resDet.form_3m_3 != null){
                resDet.form_3m_3 = JSON.parse(resDet.form_3m_3)
            }
            if (resDet.fss_03_002 != null){
                resDet.fss_03_002 = JSON.parse(resDet.fss_03_002)
            }
            if (resDet.det_form_4m != null){
                resDet.det_form_4m = JSON.parse(resDet.det_form_4m)
            }
            if (resDet.det_form_5m != null){
                resDet.det_form_5m = JSON.parse(resDet.det_form_5m)
            }
            if (resDet.det_form_6m != null){
                resDet.det_form_6m = JSON.parse(resDet.det_form_6m)
            }
            if (resDet.det_form_7m != null){
                resDet.det_form_7m = JSON.parse(resDet.det_form_7m)
            }
            if (resDet.det_form_8m != null){
                resDet.det_form_8m = JSON.parse(resDet.det_form_8m)
            }
            if (resDet.det_form_9m != null){
                resDet.det_form_9m = JSON.parse(resDet.det_form_9m)
            }
            if (resDet.det_form_10m != null){
                resDet.det_form_10m = JSON.parse(resDet.det_form_10m)
            }
            if (resDet.letter_of_intent != null){
                resDet.letter_of_intent = JSON.parse(resDet.letter_of_intent)
            }
            if (resDet.proof_of_payment != null){
                resDet.proof_of_payment = JSON.parse(resDet.proof_of_payment)
            }
            if (resDet.letter_informing != null){
                resDet.letter_informing = JSON.parse(resDet.letter_informing)
            }
            if (resDet.notarized_statement != null){
                resDet.notarized_statement = JSON.parse(resDet.notarized_statement)
            }
            if (resDet.bsp_cor != null){
                resDet.bsp_cor = JSON.parse(resDet.bsp_cor)
            }
            if (resDet.bsp_rmp != null){
                resDet.bsp_rmp = JSON.parse(resDet.bsp_rmp)
            }
            if (resDet.payment_bsp_assessment != null){
                resDet.payment_bsp_assessment = JSON.parse(resDet.payment_bsp_assessment)
            }
            if (resDet.vendee_transferee != null){
                resDet.vendee_transferee = JSON.parse(resDet.vendee_transferee)
            }
            if (resDet.contract_change_ownership != null){
                resDet.contract_change_ownership = JSON.parse(resDet.contract_change_ownership)
            }
            if (resDet.proof_payment_filing_fee != null){
                resDet.proof_payment_filing_fee = JSON.parse(resDet.proof_payment_filing_fee)
            }
            if (resDet.notarized_det_3m != null){
                resDet.notarized_det_3m = JSON.parse(resDet.notarized_det_3m)
            }
            if (resDet.contract_agreement != null){
                resDet.contract_agreement = JSON.parse(resDet.contract_agreement)
            }
            if (resDet.nbi_clearance != null){
                resDet.nbi_clearance = JSON.parse(resDet.nbi_clearance)
            }
            if (resDet.proof_attendance != null){
                resDet.proof_attendance = JSON.parse(resDet.proof_attendance)
            }
            if (resDet.schedule_ownership != null){
                resDet.schedule_ownership = JSON.parse(resDet.schedule_ownership)
            }
            if (resDet.death_certificate != null){
                resDet.death_certificate = JSON.parse(resDet.death_certificate)
            }
            if (resDet.extrajudicial_settlement != null){
                resDet.extrajudicial_settlement = JSON.parse(resDet.extrajudicial_settlement)
            }
            if (resDet.affidavit_release != null){
                resDet.affidavit_release = JSON.parse(resDet.affidavit_release)
            }
            if (resDet.compliance_cor_1 != null){
                resDet.compliance_cor_1 = JSON.parse(resDet.compliance_cor_1)
            }
            if (resDet.business_license != null){
                resDet.business_license = JSON.parse(resDet.business_license)
            }
            if (resDet.amlcs != null){
                resDet.amlcs = JSON.parse(resDet.amlcs)
            }
            if (resDet.sp_location.mother_of_contract != null){
                resDet.mother_of_contract = JSON.parse(resDet.sp_location.mother_of_contract)
            }
            
            this.$store.commit('bsp/SET_DEF_FILES', resDet)

			// Compliance
			if (this.currUser.user_role == 'approver'){
				this.$store.commit('bsp/SET_ENCODER_VIEW', true)
				this.$store.commit('bsp/SET_DETENCODER_VIEW', true)
			}
			// Encoder
			if (this.currUser.user_role == 'encoder' && this.currUser.is_det_encoder == '0' && (this.existingDetFiles.det_status == 'DET Encoder (Returned to Account Manager)' || this.existingDetFiles.det_status == 'DET Compliance (Returned to Account Manager)' || this.existingDetFiles.det_status == 'New')){
				console.log('Encoder')
				this.$store.commit('bsp/SET_ENCODER_VIEW', true)
				this.$store.commit('bsp/SET_DETENCODER_VIEW', false)
			}
			if (this.currUser.user_role == 'encoder' && this.currUser.is_det_encoder == '0' && (this.existingDetFiles.det_status == 'DET Encoder Pending' || this.existingDetFiles.det_status == 'DET Compliance Approval Pending'|| this.existingDetFiles.det_status == 'DET Approved' || this.existingDetFiles.det_status == 'Deletion Pending' || this.existingDetFiles.det_status == 'DET Compliance (Returned to DET Encoder)' || this.existingDetFiles.det_status == 'OPRN Received by Compliance')){
				console.log('Encoder')
				this.$store.commit('bsp/SET_ENCODER_VIEW', true)
				this.$store.commit('bsp/SET_DETENCODER_VIEW', true)
			}
			// DET Encoder
			if(this.currUser.user_role == 'encoder' && this.currUser.is_det_encoder == '1' && (this.existingDetFiles.det_status == 'DET Encoder Pending' || this.existingDetFiles.det_status == 'DET Compliance (Returned to DET Encoder)')){
				console.log('DET Encoder')
				this.$store.commit('bsp/SET_ENCODER_VIEW', false)
				this.$store.commit('bsp/SET_DETENCODER_VIEW', true)
			}
			
		},
		change(payload){
			this.comment = payload
		},
		assignData (data) {
			this.status = data.status
			var details = {}
			details.created_at  = data.created_at
			details.profile = data.profile
			details.status = data.status
			details.account_name = data.sp_primary_details.account_name
			details.entity_type = data.sp_other_primary_details.type
			this.$store.commit('salesProspecting/SET_PRIMARY_DETAILS', details)
			if (data.sp_primary_contact_information) {
				this.$store.commit('salesProspecting/SET_PRIMARY_CONTACT_INFO', data.sp_primary_contact_information) 
			}
		},
		async saveDraft(){
			const payload = {
				id: this.$route.params.id,
				finalPayload:{
					bsp_id : this.bspId,
					is_draft: true,
					citizenship: this.detDetails.citizenship,
					tin_no: this.detDetails.tin_no,
					fax_no: this.detDetails.fax_no,
					det_type: this.detDetails.det_type,
					ownership_type: this.detDetails.ownership_type
				}
			}
			const res = await this.$store.dispatch('bsp/doAddDetDetails', {payload})
			if (res.status === 200){
				if (this.currUser.user_role === 'encoder' && this.currUser.is_det_encoder === '1'){
					this.$router.push('/bsp-encoder');
				} else if (this.currUser.user_role === 'encoder' && this.currUser.is_det_encoder === '0'){
					this.$router.push('/bsp');
				}
				this.$store.dispatch('app/setSnackBar',{'status': true,'message':'DET Successfully Updated!','messagetype':'success'});
			}
		},
		async sendToDetEncoder(){

			this.toUpload = []
			let docs = this.uploadingOfDocuments

			if (docs.type == 'Single Proprietor'){
				if (docs.dti && docs.cmap && docs.bir_cor && docs.supplier_reference && docs.fss_deed_of_undertaking && docs.fss_pdaf && docs.fs_itr){
					this.sendToDetEncoder2()
				} else {
					if (!docs.dti){
						this.toUpload.push('DTI')
					}
					if (!docs.cmap){
						this.toUpload.push('CMAP')
					}
					if (!docs.bir_cor){
						this.toUpload.push('BIR COR')
					}
					if (!docs.supplier_reference){
						this.toUpload.push('Supplier Reference')
					}
					if (!docs.fss_deed_of_undertaking){
						this.toUpload.push('FSS Licensing Form No. 01-003')
					}
					if (!docs.fss_pdaf){
						this.toUpload.push('FSS Licensing Form No. 01-004 (PDAF)')
					}
					if (!docs.fs_itr){
						this.toUpload.push('FS/ITR')
					}
					this.snackbar = true
				}
			}

			if (docs.type == 'Cooperative/Hybrid'){
				if (docs.business_permit && docs.cda && docs.cmap && docs.secretary_certificate && docs.bir_cor && docs.supplier_reference && docs.org_chart && docs.board_resolution && docs.fss_deed_of_undertaking && docs.fss_pdaf && docs.fs_itr){
					this.sendToDetEncoder2()
				} else {
					if (!docs.business_permit){
						this.toUpload.push('Business Permit with Money Remittance/Remittance Agent')
					}
					if (!docs.cda){
						this.toUpload.push('CDA')
					}
					if (!docs.cmap){
						this.toUpload.push('CMAP')
					}
					if (!docs.secretary_certificate){
						this.toUpload.push('Secretary Certificate')
					}
					if (!docs.bir_cor){
						this.toUpload.push('BIR COR/Certificate of Tax Exemption')
					}
					if (!docs.supplier_reference){
						this.toUpload.push('Supplier Reference')
					}
					if (!docs.org_chart){
						this.toUpload.push('Organizational Chart')
					}
					if (!docs.board_resolution){
						this.toUpload.push('Board Resolution')
					}
					if (!docs.fss_deed_of_undertaking){
						this.toUpload.push('FSS Licensing Form No. 01-003')
					}
					if (!docs.fss_pdaf){
						this.toUpload.push('FSS Licensing Form No. 01-004 (PDAF)')
					}
					if (!docs.fs_itr){
						this.toUpload.push('FS/ITR')
					}
					
					this.snackbar = true
				}
			}

			if (docs.type == 'Corporation'){
				if (docs.business_permit && docs.gis && docs.sec && docs.cmap && docs.secretary_certificate && docs.bir_cor && docs.supplier_reference && docs.org_chart && docs.board_resolution && docs.fss_deed_of_undertaking && docs.fss_pdaf && docs.fs_itr){
					this.sendToDetEncoder2()
				} else {
					if (!docs.business_permit){
						this.toUpload.push('Business Permit with Money Remittance/Remittance Agent')
					}
					if (!docs.gis){
						this.toUpload.push('GIS')
					}
					if (!docs.sec){
						this.toUpload.push('SEC')
					}
					if (!docs.cmap){
						this.toUpload.push('CMAP')
					}
					if (!docs.secretary_certificate){
						this.toUpload.push('Secretary Certificate')
					}
					if (!docs.bir_cor){
						this.toUpload.push('BIR COR')
					}
					if (!docs.supplier_reference){
						this.toUpload.push('Supplier Reference')
					}
					if (!docs.org_chart){
						this.toUpload.push('Organizational Chart')
					}
					if (!docs.board_resolution){
						this.toUpload.push('Board Resolution')
					}
					if (!docs.fss_deed_of_undertaking){
						this.toUpload.push('FSS Licensing Form No. 01-003')
					}
					if (!docs.fss_pdaf){
						this.toUpload.push('FSS Licensing Form No. 01-004 (PDAF)')
					}
					if (!docs.fs_itr){
						this.toUpload.push('FS/ITR')
					}
					this.snackbar = true
				}
			}
		},
		async sendToDetEncoder2(){
			if (this.detDetails.det_type){

				this.$store.commit('salesProspecting/SET_RULE_GENERIC')
		
				setTimeout(() => {
					if (this.$refs.toValidate.validate()) {
						this.uploadDetFiles()
					} else {
						this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
					}
				})
				
			} else {
				this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Select DET Type!','messagetype':'error'},{ root: true });
			}
		},
		async callSendToDetEncoder(){
			const payload = {
				id: this.$route.params.id,
				finalPayload:{
					bsp_id : this.bspId,
					is_draft: false,
					citizenship: this.detDetails.citizenship,
					tin_no: this.detDetails.tin_no,
					fax_no: this.detDetails.fax_no,
					det_type: this.detDetails.det_type,
					ownership_type: this.detDetails.ownership_type
				}
			}
			const res = await this.$store.dispatch('bsp/doAddDetDetails', {payload})
			if (res.status === 200){
				this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Sent to DET Encoder!','messagetype':'success'});
				this.$router.push('/bsp');
			}
		},
		async sendForComplianceApproval(){
			if (this.currUser.is_det_encoder == '0'){
					const payload = {
					id: this.$route.params.id,
					finalPayload:{
						bsp_id : this.bspId,
						sent_by: this.sender,
						is_draft: true,
						citizenship: this.detDetails.citizenship,
						tin_no: this.detDetails.tin_no,
						fax_no: this.detDetails.fax_no,
						det_type: this.detDetails.det_type,
						ownership_type: this.detDetails.ownership_type
					}
				}
				const res = await this.$store.dispatch('bsp/doAddDetDetails', {payload})
				if (res.status === 200) {
					this.callSendToCompliance()
				}
			}
			if (this.currUser.is_det_encoder == '1'){
				this.sender = 'DET Encoder'
				this.$store.commit('salesProspecting/SET_RULE_FILE')

				setTimeout(() => {
					if (this.$refs.toValidate.validate()) {
						this.uploadDetFiles()
					} else {
						this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'There are fields that have not been filled out!','messagetype':'error'},{ root: true });
					}
				})
			}		
		},
		async callUploadDetEncoder(){
			const payload = {
				id: this.$route.params.id,
				finalPayload:{
					bsp_id : this.bspId,
					sent_by: this.sender,
					is_draft: true,
					citizenship: this.detDetails.citizenship,
					tin_no: this.detDetails.tin_no,
					fax_no: this.detDetails.fax_no,
					det_type: this.detDetails.det_type,
					ownership_type: this.detDetails.ownership_type
				}
			}
			const res = await this.$store.dispatch('bsp/doAddDetDetails', {payload})
			if (res.status === 200){
				this.callSendToCompliance()
			}
		},
		async callSendToCompliance(){
			if (this.currUser.is_det_encoder == '0'){
				this.sender = 'Account Manager'
			}
			if (this.currUser.is_det_encoder == '1'){
				this.sender = 'DET Encoder'
			}
			const payload = {
				id: this.$route.params.id,
				finalPayload:{
					bsp_id : this.bspId,
					sent_by: this.sender
				}
			}
			console.log(payload)
			const res = await this.$store.dispatch('bsp/doSendToCompliance', {payload})
			if (res.status === 200){
				console.log(res)
				this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Sent to Compliance Approver!','messagetype':'success'});
				if (this.currUser.is_det_encoder == '0'){
					this.$router.push('/bsp');
				}
				if (this.currUser.is_det_encoder == '1'){
					this.$router.push('/bsp-encoder');
				}
			}
		},
		async returnToEncoder(){
			
			if (!this.comment){
				this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Comment is Required!','messagetype':'error'},{ root: true });
			} else {
				const payload = {
					id: this.$route.params.id,
					finalPayload:{
						bsp_id : this.bspId,
						comment: this.comment
					}
				}
				const res = await this.$store.dispatch('bsp/doReturnToEncoder', {payload})
				if (res.status === 200){
					console.log(res)
					this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Returned to Account Manager!','messagetype':'success'});
					this.$router.push('/bsp-encoder');
				}
			}
		},
		async uploadDetFiles () {

            this.detFilesUpload.bsp_id = this.bspId
            
            const initialPayload = this.detFilesUpload
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)

			const payload = {id: this.$route.params.id, payload: convertedFormData}
			const res = await this.$store.dispatch('bsp/doUploadDetDocuments', {payload})
			if (res.status === 200){
				if (this.currUser.is_det_encoder == '0'){
					this.callSendToDetEncoder()
				}
				if (this.currUser.is_det_encoder == '1'){
					this.callUploadDetEncoder()
				}
            } 
		},
	}
	
}
</script>

<style scoped>
.link {
	color: blue;
	text-decoration: underline;
	cursor: pointer;
}
</style>