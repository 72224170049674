<template>
<div class="mb-5">
    <div v-if="detDetails.det_type === 'Registration of a MSB - Stage 1'">
        <MSB1/>
    </div>
    <div v-if="detDetails.det_type === 'Registration of a MSB - Stage 2'">
        <MSB2/>
    </div>
    <div v-if="detDetails.det_type === 'Change of Registered/Business Name (DET to generate Form No. 2M)'">
        <ChangeBusinessName />
    </div>
    <div v-if="detDetails.det_type === 'Change in Ownership or Control'">
        <ChangeOwner/>
    </div>
    <div v-if="detDetails.det_type === 'Reclassification of Registration Category'">
        <ReclassificationRegistration/>
    </div>
    <div v-if="detDetails.det_type === 'Establishment of New Branches and/or Newly-accredited Remittance Sub-agents (DET to generate Form No. 4M)'">
        <NewBranch/>
    </div>
    <div v-if="detDetails.det_type === 'Additional/Terminated Remittance Tie-up Partner/s (DET to generate Form No. 5M)'">
        <AdditionalRemittance/>
    </div>
    <div v-if="detDetails.det_type === 'Transfer of Location of Office/Remittance Sub-agent (DET to generate Form No. 6M)'">
        <TransferLocation/>
    </div>
    <div v-if="detDetails.det_type === 'Additional/Terminated Business Activity (DET to generate Form No. 7M)'">
        <AdditionalBusiness/>
    </div>
    <div v-if="detDetails.det_type === 'Change of Branch/Remittance Sub-agent Name (DET to generate Form No. 8M)'">
        <ChangeSubAgentName/>
    </div>
    <div v-if="detDetails.det_type === 'Closure of Branch/Remittance Sub-agent (DET to generate Form No. 9M)'">
        <ClosureOfBranch/>
    </div>
    <div v-if="detDetails.det_type === 'Closure of Business (DET to generate Form No. 10M)'">
        <ClosureOfBusiness/>
    </div>
    <v-btn v-if="currUser.user_role != 'approver' && existingDetFiles.det_status != 'DET Compliance Approval Pending' && existingDetFiles.det_status != 'DET Approved'"
        class="mr-5 my-5"
        outlined
        color="primary"
        style="float:right;"
        @click="uploadDetFiles"
        >
        <b>Upload Documents</b>
    </v-btn>
</div>

</template>

<script>

import { mapGetters } from 'vuex'
import jsonToFormData from 'json-form-data';
import MSB1 from '././documentsUploading/MSB1.vue';
import MSB2 from '././documentsUploading/MSB2.vue';
import ChangeBusinessName from '././documentsUploading/ChangeBusinessName.vue';
import ChangeOwner from '././documentsUploading/ChangeOwner.vue';
import ReclassificationRegistration from '././documentsUploading/ReclassificationRegistration.vue';
import NewBranch from '././documentsUploading/NewBranch.vue';
import AdditionalRemittance from '././documentsUploading/AdditionalRemittance.vue';
import TransferLocation from '././documentsUploading/TransferLocation.vue';
import AdditionalBusiness from '././documentsUploading/AdditionalBusiness.vue';
import ChangeSubAgentName from '././documentsUploading/ChangeSubAgentName.vue';
import ClosureOfBranch from '././documentsUploading/ClosureOfBranch.vue';
import ClosureOfBusiness from '././documentsUploading/ClosureOfBusiness.vue';


export default {
    components: {
		MSB1: MSB1,
		MSB2: MSB2,
		ChangeBusinessName: ChangeBusinessName,
		ChangeOwner: ChangeOwner,
		ReclassificationRegistration: ReclassificationRegistration,
		NewBranch: NewBranch,
		AdditionalRemittance: AdditionalRemittance,
		TransferLocation: TransferLocation,
		AdditionalBusiness: AdditionalBusiness,
		ChangeSubAgentName: ChangeSubAgentName,
		ClosureOfBranch: ClosureOfBranch,
		ClosureOfBusiness: ClosureOfBusiness,
	},
    computed: {
		...mapGetters({
            detDetails: 'bsp/detDetails',
            detFilesUpload: 'bsp/detFilesUpload',
            bspId: 'bsp/bspId',
            currUser: 'auth/currUser',
            existingDetFiles: 'bsp/existingDetFiles'
		}),
	},
    methods: {
        async uploadDetFiles () {

            this.detFilesUpload.bsp_id = this.bspId
            
            const initialPayload = this.detFilesUpload
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)

			const payload = {id: this.$route.params.id, payload: convertedFormData}
			const res = await this.$store.dispatch('bsp/doUploadDetDocuments', {payload})
			if (res.status === 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Uploaded Successfully!','messagetype':'success'});
                this.$store.commit('bsp/DET_FILES_DEFAULT')
                this.reloadDET()
            } 
		},
        async reloadDET () {
            const payload = {
                id: this.$route.params.id,
                finalPayload: {
                    bsp_id: this.bspId
                }
            }
			const res = await this.$store.dispatch('bsp/doGetByBspId', {payload})
            if (res.status === 200){
                this.decodeDET(res.data.result)
            }
		},
        decodeDET (resDet) {
            if (resDet.fss_02_001 != null){
                resDet.fss_02_001 = JSON.parse(resDet.fss_02_001)
            }
            if (resDet.det_fss_03_001 != null){
                resDet.det_fss_03_001 = JSON.parse(resDet.det_fss_03_001)
            }
            if (resDet.fss_03_003 != null){
                resDet.fss_03_003 = JSON.parse(resDet.fss_03_003)
            }
            if (resDet.fss_03_005 != null){
                resDet.fss_03_005 = JSON.parse(resDet.fss_03_005)
            }
            if (resDet.det_form_03_009 != null){
                resDet.det_form_03_009 = JSON.parse(resDet.det_form_03_009)
            }
            if (resDet.det_form_2m != null){
                resDet.det_form_2m = JSON.parse(resDet.det_form_2m)
            }
            if (resDet.det_form_3m != null){
                resDet.det_form_3m = JSON.parse(resDet.det_form_3m)
            }
            if (resDet.form_3m_1 != null){
                resDet.form_3m_1 = JSON.parse(resDet.form_3m_1)
            }
            if (resDet.form_3m_2 != null){
                resDet.form_3m_2 = JSON.parse(resDet.form_3m_2)
            }
            if (resDet.form_3m_3 != null){
                resDet.form_3m_3 = JSON.parse(resDet.form_3m_3)
            }
            if (resDet.fss_03_002 != null){
                resDet.fss_03_002 = JSON.parse(resDet.fss_03_002)
            }
            if (resDet.det_form_4m != null){
                resDet.det_form_4m = JSON.parse(resDet.det_form_4m)
            }
            if (resDet.det_form_5m != null){
                resDet.det_form_5m = JSON.parse(resDet.det_form_5m)
            }
            if (resDet.det_form_6m != null){
                resDet.det_form_6m = JSON.parse(resDet.det_form_6m)
            }
            if (resDet.det_form_7m != null){
                resDet.det_form_7m = JSON.parse(resDet.det_form_7m)
            }
            if (resDet.det_form_8m != null){
                resDet.det_form_8m = JSON.parse(resDet.det_form_8m)
            }
            if (resDet.det_form_9m != null){
                resDet.det_form_9m = JSON.parse(resDet.det_form_9m)
            }
            if (resDet.det_form_10m != null){
                resDet.det_form_10m = JSON.parse(resDet.det_form_10m)
            }
            if (resDet.letter_of_intent != null){
                resDet.letter_of_intent = JSON.parse(resDet.letter_of_intent)
            }
            if (resDet.proof_of_payment != null){
                resDet.proof_of_payment = JSON.parse(resDet.proof_of_payment)
            }
            if (resDet.letter_informing != null){
                resDet.letter_informing = JSON.parse(resDet.letter_informing)
            }
            if (resDet.notarized_statement != null){
                resDet.notarized_statement = JSON.parse(resDet.notarized_statement)
            }
            if (resDet.bsp_cor != null){
                resDet.bsp_cor = JSON.parse(resDet.bsp_cor)
            }
            if (resDet.bsp_rmp != null){
                resDet.bsp_rmp = JSON.parse(resDet.bsp_rmp)
            }
            if (resDet.payment_bsp_assessment != null){
                resDet.payment_bsp_assessment = JSON.parse(resDet.payment_bsp_assessment)
            }
            if (resDet.vendee_transferee != null){
                resDet.vendee_transferee = JSON.parse(resDet.vendee_transferee)
            }
            if (resDet.contract_change_ownership != null){
                resDet.contract_change_ownership = JSON.parse(resDet.contract_change_ownership)
            }
            if (resDet.proof_payment_filing_fee != null){
                resDet.proof_payment_filing_fee = JSON.parse(resDet.proof_payment_filing_fee)
            }
            if (resDet.notarized_det_3m != null){
                resDet.notarized_det_3m = JSON.parse(resDet.notarized_det_3m)
            }
            if (resDet.contract_agreement != null){
                resDet.contract_agreement = JSON.parse(resDet.contract_agreement)
            }
            if (resDet.nbi_clearance != null){
                resDet.nbi_clearance = JSON.parse(resDet.nbi_clearance)
            }
            if (resDet.proof_attendance != null){
                resDet.proof_attendance = JSON.parse(resDet.proof_attendance)
            }
            if (resDet.schedule_ownership != null){
                resDet.schedule_ownership = JSON.parse(resDet.schedule_ownership)
            }
            if (resDet.death_certificate != null){
                resDet.death_certificate = JSON.parse(resDet.death_certificate)
            }
            if (resDet.extrajudicial_settlement != null){
                resDet.extrajudicial_settlement = JSON.parse(resDet.extrajudicial_settlement)
            }
            if (resDet.affidavit_release != null){
                resDet.affidavit_release = JSON.parse(resDet.affidavit_release)
            }
            if (resDet.compliance_cor_1 != null){
                resDet.compliance_cor_1 = JSON.parse(resDet.compliance_cor_1)
            }
            if (resDet.business_license != null){
                resDet.business_license = JSON.parse(resDet.business_license)
            }
            if (resDet.amlcs != null){
                resDet.amlcs = JSON.parse(resDet.amlcs)
            }
            if (resDet.sp_location.mother_of_contract != null){
                resDet.mother_of_contract = JSON.parse(resDet.sp_location.mother_of_contract)
            }
            
            this.$store.commit('bsp/SET_DEF_FILES', resDet)
		},
    }
}
</script>
