<template>
    <v-expansion-panels class="px-5">
        <v-expansion-panel >
            <v-expansion-panel-header>
                    Documents to Upload
            </v-expansion-panel-header>

        <v-expansion-panel-content eager>
            <div class="mx-5 mt-5 mb-5">
                
                <v-row>
                    <v-col>
                        <h5>BSP Status</h5>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                        cols="10"
                        sm="10"
                        md="10"
                        >
                        <v-autocomplete
                            dense
                            solo
                            :items="items"
                            placeholder="BSP Status"
                            v-model="compliance.detStatus"
                            @change="changeStatus"
                            :readonly='disabled'
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <!-- Uploading -->

                <v-row>
                    <v-col :cols="9">
                        <h5>Notice of Return Receipt (FSD9)</h5>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="9">
                        <v-file-input
                            prepend-icon="mdi-paperclip"
                            dense
                            outlined
                            hide-details="auto"
                            :disabled='disabled'
                            v-model="complianceUpload.norr_fsd9.file"
                            :rules="checkRule(existingComplianceDocs.norr_fsd9)"
                            >
                        </v-file-input>
                    </v-col>
                    <v-col :cols="1" class="mt-1" v-if="existingComplianceDocs.norr_fsd9 != null">
                        <v-icon
                            @click="retrieveUploaded(existingComplianceDocs.norr_fsd9)"
                            >
                            mdi-download-box-outline
                        </v-icon>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col :cols="9">
                        <h5>Notice of Return Receipt (FSS Mail)</h5>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="9">
                        <v-file-input
                            prepend-icon="mdi-paperclip"
                            dense
                            outlined
                            hide-details="auto"
                            :disabled='disabled'
                            v-model="complianceUpload.norr_fss_mail.file"
                            :rules="checkRule(existingComplianceDocs.norr_fss_mail)"
                            >
                        </v-file-input>
                    </v-col>
                    <v-col :cols="1" class="mt-1" v-if="existingComplianceDocs.norr_fss_mail != null">
                        <v-icon
                            @click="retrieveUploaded(existingComplianceDocs.norr_fss_mail)"
                            >
                            mdi-download-box-outline
                        </v-icon>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col :cols="9">
                        <h5>OPRN</h5>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="9">
                        <v-file-input
                            prepend-icon="mdi-paperclip"
                            dense
                            outlined
                            hide-details="auto"
                            :disabled='disabled'
                            v-model="complianceUpload.oprn_invoice.file"
                            :rules="checkRule(existingComplianceDocs.oprn_invoice)"
                            >
                        </v-file-input>
                    </v-col>
                    <v-col :cols="1" class="mt-1" v-if="existingComplianceDocs.oprn_invoice != null">
                        <v-icon
                            @click="retrieveUploaded(existingComplianceDocs.oprn_invoice)"
                            >
                            mdi-download-box-outline
                        </v-icon>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col :cols="9">
                        <h5>OPRN Receipt</h5>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="9">
                        <v-file-input
                            prepend-icon="mdi-paperclip"
                            dense
                            outlined
                            hide-details="auto"
                            :disabled='disabled'
                            v-model="complianceUpload.oprn_receipt.file"
                            :rules="checkRule(existingComplianceDocs.oprn_receipt)"
                            >
                        </v-file-input>
                    </v-col>
                    <v-col :cols="1" class="mt-1" v-if="existingComplianceDocs.oprn_receipt != null">
                        <v-icon
                            @click="retrieveUploaded(existingComplianceDocs.oprn_receipt)"
                            >
                            mdi-download-box-outline
                        </v-icon>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col :cols="9">
                        <h5>Settlement of OPRN Payment (Account Manager)</h5>
                    </v-col>
                </v-row>
                <v-row v-if="this.currUser.user_role === 'encoder' && this.currUser.is_det_encoder == '0' && this.existingDetFiles.det_status == 'OPRN Received by Compliance'">
                    <v-col :cols="9">
                        <v-file-input
                            prepend-icon="mdi-paperclip"
                            dense
                            outlined
                            hide-details="auto"
                            v-model="complianceUpload.settle_oprn_payment.file"
                            :rules="checkRule(existingComplianceDocs.settle_oprn_payment)"
                            >
                        </v-file-input>
                    </v-col>
                    <v-col :cols="1" class="mt-1" v-if="existingComplianceDocs.settle_oprn_payment != null">
                        <v-icon
                            @click="retrieveUploaded(existingComplianceDocs.settle_oprn_payment)"
                            >
                            mdi-download-box-outline
                        </v-icon>
                    </v-col>
                </v-row>

                <v-row v-else>
                    <v-col :cols="9">
                        <v-file-input
                            prepend-icon="mdi-paperclip"
                            dense
                            outlined
                            hide-details="auto"
                            disabled
                            v-model="complianceUpload.settle_oprn_payment.file"
                            >
                        </v-file-input>
                    </v-col>
                    <v-col :cols="1" class="mt-1" v-if="existingComplianceDocs.settle_oprn_payment != null">
                        <v-icon
                            @click="retrieveUploaded(existingComplianceDocs.settle_oprn_payment)"
                            >
                            mdi-download-box-outline
                        </v-icon>
                    </v-col>
                </v-row>




                <v-row>
                    <v-col :cols="9">
                        <h5>Accreditation Letter from BSP</h5>
                    </v-col>
                </v-row>

                <v-checkbox
                    class="mb-5"
                    label="Currently in process on BSP"
                    color="indigo"
                    hide-details="auto"
                    v-model="complianceUpload.is_process_bsp"
                    :readonly='disabled'
                ></v-checkbox>

                <v-row v-if="complianceUpload.is_process_bsp == false">
                    <v-col :cols="9">
                        <v-file-input
                            prepend-icon="mdi-paperclip"
                            dense
                            outlined
                            hide-details="auto"
                            :disabled='disabled'
                            v-model="complianceUpload.accreditation_letter.file"
                            :rules="checkRule(existingComplianceDocs.accreditation_letter)"
                            >
                        </v-file-input>
                    </v-col>
                    <v-col :cols="1" class="mt-1" v-if="existingComplianceDocs.accreditation_letter != null">
                        <v-icon
                            @click="retrieveUploaded(existingComplianceDocs.accreditation_letter)"
                            >
                            mdi-download-box-outline
                        </v-icon>
                    </v-col>
                </v-row>

                <v-row class="mt-2 mx-3 mb-2">

                    <v-col v-if="this.currUser.user_role === 'approver' && this.currUser.is_det_compliance_approver === '1'">
                        <v-btn @click="uploadDocuments" outlined color="primary" style="float:right;">
                            Upload Documents
                        </v-btn>
                    </v-col>

                    <v-col v-if="this.currUser.user_role === 'encoder' && this.currUser.is_det_encoder == '0' && this.existingDetFiles.det_status == 'OPRN Received by Compliance'">
                        <v-btn @click="uploadDocuments" outlined color="primary" style="float:right;">
                            Upload Document
                        </v-btn>
                    </v-col>

                </v-row>


            </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>

</template>

<script>

import { mapGetters } from 'vuex'
import jsonToFormData from 'json-form-data';


export default{
    props: ['disabled'],
    computed: {
		...mapGetters({
			primaryContactInfo: 'salesProspecting/primaryContactInfo',
            detDetails: 'bsp/detDetails',
            currUser: 'auth/currUser',
            compliance: 'bsp/compliance',
            bspId: 'bsp/bspId',
            existingDetFiles: 'bsp/existingDetFiles',
            complianceUpload: 'bsp/complianceUpload',
            existingComplianceDocs: 'bsp/existingComplianceDocs',
		}),
	},
    data () {
      return {
        items: ['Sent to BSP FSD9', 'Notice of Return Receipt (FSD9)','Sent to BSP FSS Mail','Notice of Return Receipt (FSS Mail)','OPRN Received by Compliance','OPRN Paid by Account Manager','Currently in process on BSP','Accreditation Letter from BSP'],
      }
    },
    methods: {
        checkRule (item) {
            if (item == null) {
                return [v => !!v || 'This field is required']
            }
        },
        async changeStatus(){
            const payload = {
                id : this.$route.params.id,
                finalPayload : {
                    bsp_id : this.bspId,
                    status: this.compliance.detStatus
                }
            }
            const res = await this.$store.dispatch('bsp/doUpdateStatus', {payload})
            if (res.status === 200){
                console.log(res)
            }
        },
        async uploadDocuments(){
            // Encoder
            if (this.currUser.user_role === 'encoder' && this.currUser.is_det_encoder == '0' && this.existingDetFiles.det_status == 'OPRN Received by Compliance'){
                if (!this.complianceUpload.settle_oprn_payment.file && !this.existingComplianceDocs.settle_oprn_payment){
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Please Upload Settlement of OPRN Payment!','messagetype':'error'});
                } else {
                    this.callUploadDocuments()
                }
            }
            // Compliance Approver
            if (this.currUser.user_role === 'approver' && this.currUser.is_det_compliance_approver === '1'){
                this.callUploadDocuments()
            }
        },
        async callUploadDocuments(){
            console.log(this.complianceUpload)

            this.complianceUpload.bsp_id = this.bspId
            
            const initialPayload = this.complianceUpload
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)

			const payload = {id: this.$route.params.id, payload: convertedFormData}
			const res = await this.$store.dispatch('bsp/doUploadComplianceDocuments', {payload})
			if (res.status === 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Uploaded Successfully!','messagetype':'success'});
                this.$store.commit('bsp/SET_COMPLIANCEUPLOAD_DEFAULT')
                this.reloadComplianceDocs()
            } 
        },
        async reloadComplianceDocs() {
            const payload = {
                id: this.$route.params.id,
                finalPayload: {
                    bsp_id: this.bspId
                }
            }
			const res = await this.$store.dispatch('bsp/doGetByBspId', {payload})
            if (res.status === 200){
				console.log(res)
                this.decodeDocs(res.data.result)
            }
		},
        decodeDocs(resDet){

            console.log(resDet)

            if (resDet.norr_fsd9 != null){
                resDet.norr_fsd9 = JSON.parse(resDet.norr_fsd9)
            }
            if (resDet.norr_fss_mail != null){
                resDet.norr_fss_mail = JSON.parse(resDet.norr_fss_mail)
            }
            if (resDet.oprn_invoice != null){
                resDet.oprn_invoice = JSON.parse(resDet.oprn_invoice)
            }
            if (resDet.oprn_receipt != null){
                resDet.oprn_receipt = JSON.parse(resDet.oprn_receipt)
            }
            if (resDet.settle_oprn_payment != null){
                resDet.settle_oprn_payment = JSON.parse(resDet.settle_oprn_payment)
            }
            if (resDet.accreditation_letter != null){
                resDet.accreditation_letter = JSON.parse(resDet.accreditation_letter)
            }

            this.$store.commit('bsp/SET_ISPROCESS_BSP', resDet.is_process_bsp)
            console.log(resDet.is_process_bsp)
            this.$store.commit('bsp/SET_COMPLIANCE_DOCS', resDet)

            location.reload();
        },
        async retrieveUploaded (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
			const payload = {
				file: max.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			} else if (extension === 'xlsm') {
                blob = new Blob([file], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' })
            } else if (extension === 'xlsx') {
                blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            }
			var link = document.createElement('a');
            var fileURL = window.URL.createObjectURL(blob);
            console.log(res)
            link.href = fileURL;
            link.download = max.title;
            link.click();
            link.remove();
            window.URL.revokeObjectURL(fileURL);
		},
    }
}
</script>
