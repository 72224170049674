<template>
    <div>
        <v-row>
            <v-col :cols="9">
                <h5>Additional/Terminated Business Activity (DET to generate Form No. 7M)
                    <v-icon @click="downloadFile"> mdi-cloud-download</v-icon>
                </h5>
                
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    v-model="detFilesUpload.det_form_7m.file"
                    :disabled="disable_encoder_view"
                    :rules="detEncoderRule(existingDetFiles.det_form_7m)"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingDetFiles.det_form_7m != null">
                <v-icon
                    @click="retrieveUploaded(existingDetFiles.det_form_7m)"
                    >
                    mdi-download-box-outline
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="9">
                <h5>Memorandum of Agreement (MOA)</h5>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    v-model="detFilesUpload.mother_of_contract.file"
                    :disabled="disable_det_view"
                    :rules="checkRule(existingDetFiles.mother_of_contract)"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingDetFiles.mother_of_contract != null">
                <v-icon
                    @click="retrieveUploaded(existingDetFiles.mother_of_contract)"
                    >
                    mdi-download-box-outline
                </v-icon>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    computed: {
		...mapGetters({
            currUser: 'auth/currUser',
            detFilesUpload: 'bsp/detFilesUpload',
            existingDetFiles: 'bsp/existingDetFiles',
            disable_encoder_view: 'bsp/disable_encoder_view',
            disable_det_view: 'bsp/disable_det_view'
		}),
	},
    methods: {
        detEncoderRule (item) {
            if (this.currUser.is_det_encoder == '1'){
				if (item == null) {
                    return [v => !!v || 'This field is required']
                }
			}
        },
        checkRule (item) {
            if (item == null) {
                return [v => !!v || 'This field is required']
            }
        },
        downloadFile(){
            window.open('https://www.bsp.gov.ph/Regulations/Guidelines%20and%20other%20Regulations/DET_v2.xlsm', '_blank');
        },
        async retrieveUploaded (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
			const payload = {
				file: max.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			} else if (extension === 'xlsm') {
                blob = new Blob([file], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' })
            } else if (extension === 'xlsx') {
                blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            }
			var link = document.createElement('a');
            var fileURL = window.URL.createObjectURL(blob);
            console.log(res)
            link.href = fileURL;
            link.download = max.title;
            link.click();
            link.remove();
            window.URL.revokeObjectURL(fileURL);
		},
    },
}
</script>
