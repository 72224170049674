<template>
    <div>
        <v-row>
            <v-col :cols="9">
                <h5>Data Entry Template (DET) to generate Form No. 3M
                    <v-icon @click="downloadFile1"> mdi-cloud-download</v-icon>
                </h5>
                
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="9">
                <v-file-input
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    v-model="detFilesUpload.det_form_3m.file"
                    :disabled="disable_encoder_view"
                    :rules="detEncoderRule(existingDetFiles.det_form_3m)"
                    >
                </v-file-input>
            </v-col>
            <v-col :cols="1" class="mt-1" v-if="existingDetFiles.det_form_3m != null">
                <v-icon
                    @click="retrieveUploaded(existingDetFiles.det_form_3m)"
                    >
                    mdi-download-box-outline
                </v-icon>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h5>Change Ownership Control Type:</h5>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="10"
                sm="10"
                md="10"
                >
                <v-autocomplete
                    dense
                    solo
                    :items="items"
                    placeholder="Select Type"
                    v-model="detDetails.ownership_type"
                    :readonly="disable_det_view"
                >
                </v-autocomplete>
            </v-col>
        </v-row>





        <!-- Single Proprietor -->
        <section v-if="detDetails.ownership_type == 'Single Proprietor'">
            <v-row>
                <v-col :cols="9">
                    <h5>Form No. 3M-1 Checklist of Documentary Requirements for Change/Transfer of Ownership of MSB (For Sole Proprietorship)
                        <v-icon @click="downloadFile2"> mdi-cloud-download</v-icon>
                    </h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.form_3m_1.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.form_3m_1)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.form_3m_1 != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.form_3m_1)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Letter Informing BSP of Change/Transfer of Ownership Signed by Proprietor</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.letter_informing.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.letter_informing)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.letter_informing != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.letter_informing)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Notarized statement by authorized signatory stating that:</h5>
                    <h5>A. The entity’s books of accounts, records and documents shall be preserved for five (5) years from date of last entries before the transfer of ownership</h5>
                    <h5>B. All unused accountable forms have been properly disposed of to prevent their unauthorized use</h5>
                    <h5>C. The transferor money service business (MSB) operator shall be liable for all claims arising from transactions of the entity prior to the effective date of transfer, unless the transferee MSB operator assumes such liability, in which case, such transferee MSB operator shall submit a notarized statement to that effect.</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.notarized_statement.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.notarized_statement)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.notarized_statement != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.notarized_statement)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Original BSP Certificate of Registration issued to the transferor MSB operator (in case of change in Company Name and/or Address) or an affidavit in case of loss.</h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.bsp_cor.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.bsp_cor)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.bsp_cor != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.bsp_cor)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>BSP Registration Metal Plate of head office, if any, in case of change in Company Name and/or Address.</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.bsp_rmp.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.bsp_rmp)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.bsp_rmp != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.bsp_rmp)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Payment of BSP assessment on the entity, if any, such as for delayed/non‑submission of required reports and/or non-payment of annual service fees.</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.payment_bsp_assessment.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.payment_bsp_assessment)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.payment_bsp_assessment != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.payment_bsp_assessment)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>For the Vendee/Transferee:</h5>
                    <h5>If the transferee shall continue the operation of the MSB:  </h5>
                    <h5>Duly notarized DET-generated Form 3M together with copy of acknowledgement email from FSD9Licensing@bsp.gov.ph.</h5>
                    <h5>Compliance with the requirements of Issuance of Certificate of Registration</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.vendee_transferee.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.vendee_transferee)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.vendee_transferee != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.vendee_transferee)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Copy of the duly executed contract on the change/transfer of ownership.</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.contract_change_ownership.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.contract_change_ownership)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.contract_change_ownership != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.contract_change_ownership)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Payment or proof of payment of non-refundable filing fee.</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.proof_payment_filing_fee.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.proof_payment_filing_fee)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.proof_payment_filing_fee != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.proof_payment_filing_fee)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>
        </section>





        <!-- Partnership/Corporation/Hybrid -->
        <section v-if="detDetails.ownership_type == 'Partnership/Corporation/Hybrid'">
            <v-row>
                <v-col :cols="9">
                    <h5>Form No. 3M-2 Checklist of Documentary Requirements for Change/Transfer of Ownership of MSB (For Partnership and Corporation)
                        <v-icon @click="downloadFile3"> mdi-cloud-download</v-icon>
                    </h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.form_3m_2.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.form_3m_2)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.form_3m_2 != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.form_3m_2)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Duly notarized DET-generated Form 3M together with copy of acknowledgement email from FSD9Licensing@bsp.gov.ph.</h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.notarized_det_3m.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.notarized_det_3m)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.notarized_det_3m != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.notarized_det_3m)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Copy of the contract/agreement/arrangements shall be submitted if such transaction, in itself or in relation with other/previous transactions, will:</h5>
                    <h5>A. Result in change in ownership or control in a MSB organized as a partnership or at least twenty percent (20%) of the voting shares of stock of MSBs organized as a corporation by any person, whether natural or juridical, or which will enable such person to elect, or be elected as, a director of such MSB; or</h5>
                    <h5>b. Effect a change in the majority ownership or control of the voting shares of stock of the MSB organized as a corporation from one (1) group of persons to another group.</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.contract_agreement.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.contract_agreement)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.contract_agreement != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.contract_agreement)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h4>New partners and newly elected/appointed directors shall submit:</h4>
                </v-col>
            </v-row>
           
            <v-row>
                <v-col :cols="9">
                    <h5>A. Personal Data and Authorization Form (SES Licensing Form No. 03‑005);</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.fss_03_005.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.fss_03_005)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.fss_03_005 != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.fss_03_005)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>B. NBI Clearance, or its equivalent, if issued in foreign jurisdictions (With validity of at least 3 months from the date of application); and</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.nbi_clearance.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.nbi_clearance)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.nbi_clearance != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.nbi_clearance)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>C. Proof of attendance to the mandatory training on anti-money laundering/combating the financing of terrorism.</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.proof_attendance.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.proof_attendance)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.proof_attendance != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.proof_attendance)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Schedule of Ownership/Stockholdings Before and After the Change/Transfer of Ownership/Shares.</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.schedule_ownership.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.schedule_ownership)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.schedule_ownership != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.schedule_ownership)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Payment or proof of payment of non-refundable filing fee.</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.proof_payment_filing_fee.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.proof_payment_filing_fee)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.proof_payment_filing_fee != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.proof_payment_filing_fee)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>
        </section>






        <!-- Deceased Person -->
        <section v-if="detDetails.ownership_type == 'Transfer of Ownership of Money Service Business from a Deceased Person to Heir/s'">
            <v-row>
                <v-col :cols="9">
                    <h5>Form No. 3M-3 Checklist of Documentary Requirements for Transfer of Ownership from a Deceased Person to Heir/s
                        <v-icon @click="downloadFile4"> mdi-cloud-download</v-icon>
                    </h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.form_3m_3.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.form_3m_3)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.form_3m_3 != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.form_3m_3)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Death Certificate</h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.death_certificate.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.death_certificate)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.death_certificate != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.death_certificate)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Extrajudicial Settlement of Estate/Affidavit of Self-Adjudication</h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.extrajudicial_settlement.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.extrajudicial_settlement)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.extrajudicial_settlement != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.extrajudicial_settlement)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Affidavit of Waiver and Release from Liability</h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.affidavit_release.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.affidavit_release)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.affidavit_release != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.affidavit_release)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col :cols="9">
                    <h5>Original Certificate of Registration (COR) issued by BSP (in case of change in Company Name and/or Address) or an affidavit in case of loss</h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.bsp_cor.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.bsp_cor)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.bsp_cor != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.bsp_cor)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>BSP Registration Metal Plate of head office, if any, in case of change in Company Name and/or Address</h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.bsp_rmp.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.bsp_rmp)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.bsp_rmp != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.bsp_rmp)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Duly notarized DET-generated Form 3M together with a copy of acknowledgement email from FSD9Licensing@bsp.gov.ph</h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.notarized_det_3m.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.notarized_det_3m)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.notarized_det_3m != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.notarized_det_3m)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Compliance with the requirements of Issuance of Certificate of Registration</h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.compliance_cor_1.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.compliance_cor_1)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.compliance_cor_1 != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.compliance_cor_1)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="9">
                    <h5>Payment or proof of payment of non-refundable filing fee</h5>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="9">
                    <v-file-input
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        hide-details="auto"
                        v-model="detFilesUpload.proof_payment_filing_fee.file"
                        :disabled="disable_det_view"
                        :rules="checkRule(existingDetFiles.proof_payment_filing_fee)"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="existingDetFiles.proof_payment_filing_fee != null">
                    <v-icon
                        @click="retrieveUploaded(existingDetFiles.proof_payment_filing_fee)"
                        >
                        mdi-download-box-outline
                    </v-icon>
                </v-col>
            </v-row>

        </section>

    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    data () {
		return {
            items: ['Single Proprietor','Partnership/Corporation/Hybrid','Transfer of Ownership of Money Service Business from a Deceased Person to Heir/s']
		};
	},
    computed: {
		...mapGetters({
            currUser: 'auth/currUser',
            detFilesUpload: 'bsp/detFilesUpload',
            existingDetFiles: 'bsp/existingDetFiles',
            detDetails: 'bsp/detDetails',
            primaryDetails: 'salesProspecting/primaryDetails',
            disable_encoder_view: 'bsp/disable_encoder_view',
            disable_det_view: 'bsp/disable_det_view'
		}),
	},
    methods: {
        detEncoderRule (item) {
            if (this.currUser.is_det_encoder == '1'){
				if (item == null) {
                    return [v => !!v || 'This field is required']
                }
			}
        },
        checkRule (item) {
            if (item == null) {
                return [v => !!v || 'This field is required']
            }
        },
        downloadFile1(){
            window.open('https://www.bsp.gov.ph/Regulations/Guidelines%20and%20other%20Regulations/DET_v2.xlsm', '_blank');
        },
        downloadFile2(){
            window.open('https://www.bsp.gov.ph/Regulations/Guidelines%20and%20other%20Regulations/Form%203M-1.docm', '_blank');
        },
        downloadFile3(){
            window.open('https://www.bsp.gov.ph/Regulations/Guidelines%20and%20other%20Regulations/Form%203M-2.docm', '_blank');
        },
        downloadFile4(){
            window.open('https://www.bsp.gov.ph/Regulations/Guidelines%20and%20other%20Regulations/Form%203M-3.docm', '_blank');
        },
        async retrieveUploaded (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
			const payload = {
				file: max.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			} else if (extension === 'xlsm') {
                blob = new Blob([file], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' })
            } else if (extension === 'xlsx') {
                blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            }
			var link = document.createElement('a');
            var fileURL = window.URL.createObjectURL(blob);
            console.log(res)
            link.href = fileURL;
            link.download = max.title;
            link.click();
            link.remove();
            window.URL.revokeObjectURL(fileURL);
		},
    },
}
</script>
